.conversion-cust {
    box-shadow: 0 0 0 0 !important;
    background-color: #f1eff500 !important;
}
.cust-pageHeader {
    margin: 1.5rem 0 1.5 0 !important;
}
.cust-collectionTable {
    .jxflYm {
        background: #f1eff500 !important;
    }
}
.rdt_TableCol:not(:last-child) {
    border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.custom-switch-input:checked ~ .custom-switch-indicator {
    background: #13bfa6;
    border-color: #13bfa6;
    cursor: pointer;
}
.custom-switch-input:not(:checked) ~ .custom-switch-indicator {
    background: #e73827;
    border-color: #e73827;
    cursor: pointer;
}
.custCollectionTable {
    .table-hover tbody tr:hover td {
        background: #e9ecef;
    }
}
.custCollectionTableRow {
    padding-right: 0px !important;
    padding-left: 0px !important;
}
.cust-incdcr-btn,
.cust-incdcr-input {
    height: 38px !important;
}
.cust-eligibility > p {
    margin: 10px 0px 0px 10px !important;
}
.cust-conversion-elig {
    align-items: end;
    p {
        margin-left: 15px;
        padding-right: 60px;
        margin-bottom: 0px;
        margin-top: 9px;
    }
}

.cust-option-btn {
    padding: 4px 0px 6px 2px;
    line-height: 1;
    font-size: 10px;
    &:hover {
        .text-primary {
            background-color: #6c5ffc !important;
            color: #fff !important;
        }
        .text-danger {
            background-color: #ff4d4f !important;
            color: #fff !important;
        }
        .text-default {
            background-color: #eaedf7 !important;
            color: #000 !important;
        }
    }
    .custconversionformbtns {
        padding: 1px !important;
    }
}
.cust-conversion-table-blur {
    filter: blur(3px) !important;
}